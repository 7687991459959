import React from 'react';
import Logo from "./logo.js";
import "./head.css";

function Head() {
  return (
    <div className='head'>
    <div className='logoposition'> <Logo/></div>
    <div className='menu'>
      <span>About us</span>
    </div>
     
    </div>
    
  );
}

export default Head;
