import Beyaynetu from "../images/Beyaynetu.JPG";
import burgerImg from "../images/Mahiberawi.jpeg";


export function getData() {
  return [
    { title: "በየአይነት", price: 200.00, Image: Beyaynetu,discription:"ጎመን፣ ምስር፣ ፎሶሊያ፣ ሽምብራ አሳ፣ ክክምስር፣ ጥቅል ጎመን፣ ሌሎችም  \n አንድ ምግብ 6 አይነት ",portion:"ከ 1+ ሰው የሚበቃ",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:1 },
    { title: "በየአይነት", price: 350.00, Image: Beyaynetu,discription:"ጎመን፣ ምስር፣ ፎሶሊያ፣ ሽምብራ አሳ፣ ክክምስር፣ ጥቅል ጎመን፣ ሌሎችም  \n አንድ ምግብ 6 አይነት ",portion:"ከ 2+ ሰው የሚበቃ",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:2 },
    { title: "በየአይነት", price: 650.00, Image: Beyaynetu,discription:"ጎመን፣ ምስር፣ ፎሶሊያ፣ ሽምብራ አሳ፣ ክክምስር፣ ጥቅል ጎመን፣ ሌሎችም  \n አንድ ምግብ 6 አይነት ",portion:"ከ 4+ ሰው የሚበቃ",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:3 },

    { title: "ማህበራዊ", price: 350, Image: burgerImg,discription:"ቀይ ምንቸት፣ ቀይወጥ፣ ጎመን በስጋ፣ አልጫ ምንቸት፣ ዱለት፣ ተፈርሾ፣ ሌሎችም  \n አንድ ምግብ 6 አይነት እና 1 እንጀራ ይይዛል",portion:"ከ 1+ ሰው የሚበቃ",type:"ፍስክ" ,delivery:"ደሊቨሪን ያካትታል",id:4 },
    { title: "ማህበራዊ", price: 650, Image: burgerImg,discription:"ቀይ ምንቸት፣ ቀይወጥ፣ ጎመን በስጋ፣ አልጫ ምንቸት፣ ዱለት፣ ተፈርሾ፣ ሌሎችም  \n አንድ ምግብ 6 አይነት እና 1 እንጀራ ይይዛል",portion:"ከ 2+ ሰው የሚበቃ",type:"ፍስክ" ,delivery:"ደሊቨሪን ያካትታል",id:5 },
    { title: "ማህበራዊ", price: 1200, Image: burgerImg,discription:"ቀይ ምንቸት፣ ቀይወጥ፣ ጎመን በስጋ፣ አልጫ ምንቸት፣ ዱለት፣ ተፈርሾ፣ ሌሎችም  \n አንድ ምግብ 6 አይነት እና 1 እንጀራ ይይዛል",portion:"ከ 4+ ሰው የሚበቃ",type:"ፍስክ" ,delivery:"ደሊቨሪን ያካትታል",id:6 },

    { title: "ምስር ", price: 50.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:7 },
    { title: "ምስር ", price: 90.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:8 },
    { title: "ምስር ", price: 130.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:9 },
    
    { title: "አተር ክክ አልጫ", price: 45.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:52 },
    { title: "አተር ክክ አልጫ", price: 80.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:53 },
    { title: "አተር ክክ አልጫ", price: 120.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:54 },

    { title: "ሽምብራ አሳ", price: 50.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:55 },
    { title: "ሽምብራ አሳ", price: 90.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:56 },
    { title: "ሽምብራ አሳ", price: 130.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:57 },
    

    { title: "ድንች አልጫ", price: 30.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:10 },
    { title: "ድንች አልጫ", price: 55.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:11 },
    { title: "ድንች አልጫ", price: 80.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:12 },
    

    { title: "ፎሶልያ", price: 40.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:13 },
    { title: "ፎሶልያ", price: 75.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:14 },
    { title: "ፎሶልያ", price: 110.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:15 },
    

    { title: "ጥቅል ጎመን", price: 30.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:16 },
    { title: "ጥቅል ጎመን", price: 55.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:17 },
    { title: "ጥቅል ጎመን", price: 80.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:18 },
    

    { title: "ድፍን ምስር አልጫ", price: 40.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:19 },
    { title: "ድፍን ምስር አልጫ", price: 75.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:20 },
    { title: "ድፍን ምስር አልጫ", price: 100.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:21 },
    

    { title: "አተር ፍትፍት", price: 30.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:22 },
    { title: "አተር ፍትፍት", price: 55.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:23 },
    { title: "አተር ፍትፍት", price: 80.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:24 },
    

    { title: "ጎመን", price: 40.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:25 },
    { title: "ጎመን", price: 75.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:26 },
    { title: "ጎመን", price: 100.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ጾም", delivery:"ደሊቨሪን ያካትታል",id:27 },
    

    { title: "ዱለት", price: 95.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:28 },
    { title: "ዱለት", price: 180.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:29 },
    { title: "ዱለት", price: 265.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:30 },
    

    { title: "ጎመን በስጋ", price: 50.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:31 },
    { title: "ጎመን በስጋ", price: 90.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:32 },
    { title: "ጎመን በስጋ", price: 140.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:33 },
    

    { title: "አልጫ ስጋ ፍትፍት", price: 50.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:34 },
    { title: "አልጫ ስጋ ፍትፍት", price: 90.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:35 },
    { title: "አልጫ ስጋ ፍትፍት", price: 140.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:36 },
    

    { title: "ምንቸት አልጫ", price: 70.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:37 },
    { title: "ምንቸት አልጫ", price: 130.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:38 },
    { title: "ምንቸት አልጫ", price: 190.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:39 },

    { title: "ቋንጣ ፍርፍርፍ በድርቆሽ", price: 70.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:40 },
    { title: "ቋንጣ ፍርፍርፍ በድርቆሽ", price: 130.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:41 },
    { title: "ቋንጣ ፍርፍርፍ በድርቆሽ", price: 190.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:42 },

    { title: "ምንቸት አልጫ", price: 50.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:43 },
    { title: "ምንቸት አልጫ", price: 90.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:44 },
    { title: "ምንቸት አልጫ", price: 140.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:45 },

    { title: "ምንቸት ", price: 70.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:46 },
    { title: "ምንቸት", price: 130.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:47 },
    { title: "ምንቸት ", price: 190.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:48 },

    { title: "ቀይወጥ ", price: 70.00, Image: Beyaynetu,discription:"",portion:"100g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:49 },
    { title: "ቀይወጥ", price: 130.00, Image: Beyaynetu,discription:"",portion:"200g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:50 },
    { title: "ቀይወጥ ", price: 190.00, Image: Beyaynetu,discription:"",portion:"300g",type:"ፍስክ", delivery:"ደሊቨሪን ያካትታል",id:51 },
    

 
  ];
}
