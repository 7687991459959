import React, { useState } from "react";
import "./Card.css";
import Button from "../Button/Button";
function Card({ food, onAdd, onRemove }) {
  const [count, setCount] = useState(0);
  const { title, Image, price,discription,portion,type,delivery, id } = food;

  const handleIncrement = () => {
    setCount(count + 1);
    onAdd(food);
  };
  const handleDecrement = () => {
    setCount(count - 1);
    onRemove(food);
  };

  return (
    <div className="card">
<span className={`${count !== 0 ? "card__badge" : "card__badge--hidden"}`}>
  {count}
</span>

{type === "ጾም" ? (
  <span className="card__type_tsom">{type}</span>
) : type === "ፍስክ" ? (
  <span className="card__type_fesek">{type}</span>
) : null}

     
      <div className="image__container">
        <img src={Image} alt={title} />
      </div>
      <h4 className="card__title">
        {title} <br /> <span className="card__price">{price} Birr</span>
      </h4>
      <span className="card__discription">
         {discription}
      </span>
      <span className="card__portion">
         {portion}
      </span>
      <span className="card_delivery">{delivery}</span>
      <div className="btn-container">
        <Button title={"+"} type={"add"} onClick={handleIncrement} />
        {count !== 0 ? (
          <Button title={"-"} type={"remove"} onClick={handleDecrement} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Card;
