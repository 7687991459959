// import logo from './logo.svg'; // Unused import statement, commented out

// Importing necessary React hooks and libraries
import { useState, useEffect } from "react";

// Importing CSS file for styling
import './App.css';

// Importing the Head, Card, and Cart components from specified paths
import Head from './componant/head.js';
import Card from "./componant/Card/Card";
import Cart from "./componant/Cart/Cart";

// Importing the getData function from the specified path
const { getData } = require("./db/db");

// Fetching data using the getData function and storing it in the 'foods' variable
const foods = getData();

// const tele = window.Telegram.WebApp; // Commented out, seems to be unused or incomplete

function App() {
  
  // Initializing state for cart items using the useState hook
  const [cartItems, setCartItems] = useState([]);

  // useEffect for tele.ready(); - commented out, seems to be related to tele.ready()

  // Function to handle adding an item to the cart
  const onAdd = (food) => {
    const exist = cartItems.find((x) => x.id === food.id);
    if (exist) {
      setCartItems(
        cartItems.map((x) =>
          x.id === food.id ? { ...exist, quantity: exist.quantity + 1 } : x
        )
      );
    } else {
      setCartItems([...cartItems, { ...food, quantity: 1 }]);
    }
  };

  // Function to handle removing an item from the cart
  const onRemove = (food) => {
    const exist = cartItems.find((x) => x.id === food.id);
    if (exist.quantity === 1) {
      setCartItems(cartItems.filter((x) => x.id !== food.id));
    } else {
      setCartItems(
        cartItems.map((x) =>
          x.id === food.id ? { ...exist, quantity: exist.quantity - 1 } : x
        )
      );
    }
  };

  // Function to handle the checkout process
  const onCheckout = () => {
    // tele.MainButton.text = "Pay :)"; // Commented out, seems to be related to tele.MainButton
    // tele.MainButton.show(); // Commented out, seems to be related to tele.MainButton
  };

  return (
    <>
      {/* <h1 className="heading">Order Food</h1> */}

      {/* Rendering the Head component */}
      <Head/>

      {/* Rendering the Cart component with cart items and checkout function */}
      <Cart cartItems={cartItems} onCheckout={onCheckout}/>

      {/* Rendering the Card components for each food item */}
      <div className="cards__container">
        {foods.map((food) => {
          return (
            <Card food={food} key={food.id} onAdd={onAdd} onRemove={onRemove} />
          );
        })}
      </div>
    </>
  );
}

// Exporting the App component as the default export
export default App;
