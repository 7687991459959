import React from 'react'
import "./logo.css";
function Logo() {
  return (
    <div> <div className="logocont">
       
    <div className='circle'>
      <div className='outer-circle'>
        <div className='inner-circle'>
          <div className='outer-outer-circle'>
            <div className='inner-inner-circle'></div>
          </div>
        </div>
      </div>
    </div>
    <div className='logo-text'>
      <span className='text logo'>Eatup</span>
      <span className='text'>Addis</span>
    </div>
    
  </div></div>
  )
}

export default Logo